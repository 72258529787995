@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
 
  /* font-family: Space Grotesk,ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */

  font-family:"Calibri";


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family:"Calibri";
}

::placeholder {

  /* font-family: Space Grotesk,ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
  font-family:"Calibri";

}

h1, h2, h3, p, button {
  font-family:"Calibri";

}

.nav-bg-cus{
  background-color: #007EA4;
}

.app-title{
  color: white;
  font-size: xx-large;
  align-items: center;
}